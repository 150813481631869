<template>
    <div>
        <div v-if="props.showAnimationEffect"
             :class="{'subscribe-newsletter-dark-overlay--visible': isDarkBackground }"
             class="subscribe-newsletter-dark-overlay"
             @click="dismissOverlay"/>
        <div ref="subscribeArticleContainer"
             class="subscribe-article-container"
             :class="containerClasses">
            <div class="subscribe-article">
                <div class="subscribe-article__content">
                    <div class="subscribe-article__title">
                        <Translate translationKey="subscribe.newsletterArticleTitle"
                                   fallback="Thirsty for the Hottest Crypto News in Your Inbox?" />
                    </div>
                    <div class="subscribe-article__subtitle">
                        <Translate translationKey="subscribe.newsletterArticleSubtitle"
                                   fallback="Get crypto-smart in just 5 mins a day with our newsletter! Join 70,000+ subscribers & get a juicy Daily Squeeze of the hottest, jargon-free news and insights straight to your inbox." />
                    </div>
                    <form class="subscribe-article__form"
                          @submit.prevent="handleFormSubmit">
                        <input v-model="email"
                               class="subscribe-article__input"
                               :class="{ 'subscribe-article__input--error': errorMessage }"
                               :placeholder="getTrans('subscribe.inputPlaceholder', 'Enter email here')"
                               name="email"
                               type="email">
                        <div v-if="errorMessage"
                             class="subscribe-article__error">
                            {{ errorMessage }}
                        </div>
                        <button class="subscribe-article__button quiz-button--correct"
                                type="submit">
                            <Translate translationKey="subscribe.subscribeIconText"
                                       fallback="Subscribe"/>
                        </button>
                    </form>
                </div>
                <div v-if="!isMobileOrTablet"
                     class="subscribe-article__image-container">
                    <img class="subscribe-article__image"
                         :src="asset_cdn('/assets/daily-squeeze-image.png')"
                         :alt="getTrans('subscribe.newsletterArticleTitle', 'Thirsty for Crypto Knowledge?')">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        ref, onMounted, onBeforeUnmount, computed,
    } from 'vue';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { useSubscribeForm } from '@/js/subscribe/composables/useSubscribeForm';
    import { subscriberSourceEnum } from '@/js/enums/subscriberSourceEnum';
    import Translate from '@/js/helpers/Translate.vue';
    import { getTrans } from '@/js/helpers/getTrans';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';
    import { getCookieValue, setCookie } from '@/js/helpers/cookieHelpers';

    const props = defineProps({
        showAnimationEffect: {
            type: Boolean,
            default: false,
        },
    });

    const { isMobileOrTablet } = useDeviceSize();

    const SUBSCRIBE_FORM_BUTTON_ID = 'subscribe-form-proceed-button';
    const {
        email,
        errorMessage,
        loadRecaptchaAndProceed: originalLoadRecaptchaAndProceed,
    } = useSubscribeForm(subscriberSourceEnum.SOURCE_NEWSLETTER_ARTICLE, [subscriberSourceEnum.SOURCE_NEWSLETTER, subscriberSourceEnum.SOURCE_NEWSLETTER_ARTICLE], SUBSCRIBE_FORM_BUTTON_ID, true);

    const handleFormSubmit = async () => {
        await originalLoadRecaptchaAndProceed();

        if (props.showAnimationEffect) {
            // eslint-disable-next-line no-use-before-define
            dismissOverlay();
        }
    };

    const isDarkBackground = ref(false);
    const subscribeArticleContainer = ref<HTMLElement | null>(null);
    const hasOverlayBeenShown = ref(getCookieValue('has_subscribe_overlay_been_shown') === 'true');

    const handleScroll = () => {
        if (!subscribeArticleContainer.value) return;

        const rect = subscribeArticleContainer.value.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportCenter = viewportHeight / 2;
        const elementCenter = rect.top + rect.height / 2;
        const threshold = 200;

        if (Math.abs(elementCenter - viewportCenter) < rect.height / 4 && !hasOverlayBeenShown.value) {
            isDarkBackground.value = true;
            hasOverlayBeenShown.value = true;
            setCookie('has_subscribe_overlay_been_shown', 'true', 1);
        }

        if (Math.abs(elementCenter - viewportCenter) > rect.height / 4 + threshold) {
            isDarkBackground.value = false;
        }
    };

    const dismissOverlay = () => {
        isDarkBackground.value = false;
    };

    const containerClasses = computed(() => ({
        'subscribe-article-container--animation': props.showAnimationEffect,
        'dark-background': isDarkBackground.value,
    }));

    onMounted(() => {
        if (props.showAnimationEffect) {
            window.addEventListener('scroll', handleScroll);
            handleScroll();
        }
    });

    onBeforeUnmount(() => {
        if (props.showAnimationEffect) {
            window.removeEventListener('scroll', handleScroll);
        }
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";
@import "@/sass/partials/variables";
@import "@/sass/partials/z-index";

.subscribe-newsletter-dark-overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.subscribe-newsletter-dark-overlay--visible {
    z-index: $subscribe-newsletter-dark-overlay;
    opacity: 1;
}

.subscribe-article-container.dark-background {
    position: relative;
    z-index: $subscribe-newsletter-dark-overlay;
}

.subscribe-article-container--animation {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 550px;

    @media (max-width: $md-breakpoint-max-width) {
        height: 600px;
    }

    .subscribe-article {
        position: sticky;
        top: 250px;

        @media (max-width: $md-breakpoint-max-width) {
            top: 100px;
        }
    }
}

.subscribe-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 52px 32px 60px;
    border-radius: 16px;
    background-color: $blue-solitude;
    gap: 40px;
    text-align: center;

    @media (min-width: $md-breakpoint) {
        flex-direction: row;
        gap: 32px;
        text-align: left;
    }

    &::after {
        position: absolute;
        top: -30px;
        left: 0;
        width: 70px;
        height: 77px;
        background-image: url($asset-cdn-root + "/assets/daily-squeeze-image.png");
        background-repeat: no-repeat;
        background-size: cover;
        content: "";

        @media (min-width: $md-breakpoint) {
            display: none;
        }
    }

    &__title {
        margin-top: 10px;
        margin-bottom: 10px;
        color: $baseBlack;
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
    }

    &__subtitle {
        margin-bottom: 16px;
        color: $baseBlack;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        @media (min-width: $sm-breakpoint) {
            font-size: 18px;
        }
    }

    &__form {
        position: relative;
    }

    &__input {
        width: 100%;
        height: 44px;
        padding: 6px 12px;
        border: 1px solid $baseBlack;
        border-radius: 10px;
        margin-bottom: 24px;
        background: $baseWhite;
        font-size: 16px;
        outline: none;

        &--error {
            margin-bottom: 32px;
        }
    }

    &__error {
        position: absolute;
        top: 50px;
        color: $error600;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
    }

    &__button {
        padding: 8px 40px;
    }

    &__image-container {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-end;
    }

    &__image {
        width: 150px;
        height: 150px !important;
        border: none !important;
    }
}
</style>
